import * as React from 'react';
import type {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from 'reactflow';
import { addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import { create } from 'zustand';
import { DiversionResourceType } from '~/shared/types/diversion-resource';
import type { TokenType } from '~/shared/types/token-generator.type';
import { GeneratedContentType } from '~/shared/types/generated-content.type';

export type HostNodeData = {
  id: string;
  shortcode: string;
  name: string;
  description?: string | null;
  serviceIds: string[];
};

export type ContainerNodeData = {
  id: string;
  shortcode: string;
  name: string;
  image: string;
  environmentVariables?: {
    key: string;
    value: string;
  }[];
  signalConfiguration?: {
    id?: string;
    parserMode: 'NAMED_REGEX' | 'JSON_MAP';
    parserPatterns?: string[];
    fields?: {
      id: string;
      name: string;
      description?: string;
      alias?: string;
      fieldTypeId: string;
    }[];
    events?: {
      id: string;
      fieldName: string;
      type: 'STANDARD' | 'REALTIME' | 'STREAMING';
      pattern: string;
    }[];
  };
  networkServices?: {
    shortcode: string;
    protocol: 'TCP' | 'UDP';
    containerPort: number;
    hostPort?: number;
    expose: boolean;
  }[];
  valueResolvers?: {
    id?: string;
    method:
      | 'TEXT_FILE_TEMPLATE'
      | 'TOKEN_FILE'
      | 'GEN_CONTENT'
      | 'ENVIRONMENT_VARIABLE';
    parameters?: {
      key: string;
      value: string;
    }[];
    file?: {
      objectKey: string;
      fileName: string;
    };
  }[];
};

export type PendingTokenNodeData = {
  mazeId: string;
};

export type TokenNodeData = {
  id: string;
  name: string;
  generatorType: TokenType;
  generatorParameters: Record<string, string | Record<string, any>>;
  shortcode: string;
  generatorViews: {
    name: string;
    description: string;
  }[];
  trackable: boolean;
  visible: boolean;
};

export type PendingResourceNodeData = {
  mazeId: string;
};

export type ResourceNodeData = {
  id: string;
  name: string;
  description?: string;
  type: DiversionResourceType;
  parameters: Record<string, string>;
  shortcode: string;

  signalConfiguration?: {
    id?: string;
    parserMode: 'NAMED_REGEX' | 'JSON_MAP';
    parserPatterns?: string[];
    fields?: {
      id: string;
      name: string;
      description?: string;
      alias?: string;
      fieldTypeId: string;
    }[];
    events?: {
      id: string;
      fieldName: string;
      type: 'STANDARD' | 'REALTIME' | 'STREAMING';
      pattern: string;
    }[];
  };

  valueResolvers?: {
    id?: string;
    method:
      | 'TEXT_FILE_TEMPLATE'
      | 'TOKEN_FILE'
      | 'GEN_CONTENT'
      | 'ENVIRONMENT_VARIABLE';
    parameters?: {
      key: string;
      value: string;
    }[];
    file?: {
      objectKey: string;
      fileName: string;
    };
  }[];
};

export type PendingGenContentNodeData = {
  mazeId: string;
};

export type GenContentNodeData = {
  id: string;
  name: string;
  shortcode: string;
  type: GeneratedContentType;
  parameters: {
    key: string;
    type: 'simple' | 'file';
    value: string;
  }[];
};

type SignalFieldType = {
  id: string;
  name: string;
  description?: string;
  code: string;
};

type MazeData = {
  id: string;
  name: string;
  description?: string;
  tags: string[];
  active: boolean;
  milestones: {
    id: string;
    fieldId: string;
    meta: {
      hostId?: string;
      interfaceId: string;
    };
    expression: string;
    difficulty: number;
    nextMilestoneId: string | null;
    threatThreshold: boolean;
  }[];
  namedParameters: {
    name: string;
    description?: string;
    type: 'string' | 'file';
  }[];
};

// yes, its ugly
export type ContainerTemplate = {
  label: string;
  value: {
    signalConfiguration: {
      fields: ({
        fieldTypeCode?: string;
      } & NonNullable<
        NonNullable<ContainerNodeData['signalConfiguration']>['fields']
      >[number])[];
    } & DeepPartial<ContainerNodeData>['signalConfiguration'];
  } & DeepPartial<ContainerNodeData>;
};

type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export type RFState = {
  nodes: Node<
    | HostNodeData
    | ContainerNodeData
    | PendingResourceNodeData
    | ResourceNodeData
    | PendingTokenNodeData
    | TokenNodeData
    | PendingGenContentNodeData
    | GenContentNodeData
  >[];
  edges: Edge[];

  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;

  setNodes: (
    nodes: Node<
      | HostNodeData
      | ContainerNodeData
      | PendingResourceNodeData
      | ResourceNodeData
      | PendingTokenNodeData
      | TokenNodeData
      | PendingGenContentNodeData
      | GenContentNodeData
    >[],
  ) => void;
  setEdges: (edges: Edge[]) => void;

  selectedNode?: Node<
    | HostNodeData
    | ContainerNodeData
    | ResourceNodeData
    | TokenNodeData
    | GenContentNodeData
  >;
  setSelectedNode: (nodeId?: string) => void;

  fieldTypes: SignalFieldType[];
  setFieldTypes: (data: SignalFieldType[]) => void;

  tabMode: 'none' | 'host' | 'container' | 'resource' | 'token' | 'genContent';
  setTabMode: (
    tab: 'none' | 'host' | 'container' | 'resource' | 'token' | 'genContent',
  ) => void;

  mazeData?: MazeData;
  setMazeData: (data: MazeData) => void;

  containerTemplates: ContainerTemplate[];
  setContainerTemplates: (data: ContainerTemplate[]) => void;
};

type BuilderStore = ReturnType<typeof createBuilderStore>;

const createBuilderStore = () => {
  return create<RFState>((set, get) => ({
    nodes: [],
    edges: [],

    onNodesChange: (changes: NodeChange[]) => {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
    },
    onConnect: (connection: Connection) => {
      set({
        edges: addEdge(connection, get().edges),
      });
    },

    setNodes: (
      nodes: Node<
        | HostNodeData
        | ContainerNodeData
        | ResourceNodeData
        | PendingResourceNodeData
        | TokenNodeData
        | PendingTokenNodeData
        | PendingGenContentNodeData
        | GenContentNodeData
      >[],
    ) => {
      set({
        nodes: nodes,
      });
    },
    setEdges: (edges: Edge[]) => {
      set({
        edges: edges,
      });
    },

    selectedNode: undefined,
    setSelectedNode: (nodeId?: string) => {
      if (nodeId === undefined) return;

      set({
        selectedNode: get().nodes.find((n) => n.id === nodeId) as
          | HostNodeData
          | ContainerNodeData
          | ResourceNodeData
          | TokenNodeData
          | GenContentNodeData
          | undefined,
      });
    },

    fieldTypes: [],
    setFieldTypes: (data: SignalFieldType[]) => {
      set({
        fieldTypes: data,
      });
    },

    tabMode: 'none',
    setTabMode: (tab) => {
      set({
        tabMode: tab,
      });
    },

    mazeData: undefined,
    setMazeData: (data: MazeData) => {
      set({
        mazeData: data,
      });
    },

    containerTemplates: [],
    setContainerTemplates: (data: ContainerTemplate[]) => {
      set({
        containerTemplates: data,
      });
    },
  }));
};

export const BuilderContext = React.createContext<BuilderStore | null>(null);

export default createBuilderStore;
